// AppLogic.tsx
import { h, FunctionalComponent } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { PingSession, startSession } from '../../utils';
import { setSessionId } from '../../redux/slices/globalsSlice';

const AppLogic: FunctionalComponent = () => {

    const appearance = useAppSelector((state) => state.appearance);
    const search = useAppSelector((state) => state.search);
    const globals = useAppSelector((state) => state.globals);
    const alias = useAppSelector((state) => state.alias);
    const auth = useAppSelector((state) => state.auth);

    const dispatch = useAppDispatch();
    const [tabStatusChanged, SetStatusChanged] = useState<boolean>(false);


    useEffect(() => {
        const handleVisibilityChange = async () => {
            if (alias.workspaceId && alias.portalId && globals.sessionId && globals.sessionUserId) {
                try {
                    if (!document.hidden) {
                        const pingResponse = await PingSession(alias.workspaceId, alias.portalId, globals.sessionId, globals.sessionUserId, globals.jwtUserIdToken,false);
                        pingResponse && dispatch(setSessionId(pingResponse?.sessionId))
                        console.log(pingResponse);
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [alias, globals]);

    useEffect(() => {
        const sessionStarter = async () => {
            if (alias.workspaceId && alias.portalId && globals.sessionUserId) {
                if (!globals.sessionId) {
                    try {
                        const startSessionResponse = await startSession(alias.workspaceId, alias.portalId, globals.sessionUserId, globals.jwtUserIdToken,false)
                        if (startSessionResponse?.sessionId) {
                            dispatch(setSessionId(startSessionResponse?.sessionId))
                        }
                    } catch (error) {
                        console.error(error)
                    }

                }
            };
        };
        sessionStarter();
    }, [globals.sessionUserId, globals.jwtUserIdToken, alias.workspaceId, alias.portalId]);


    useEffect(() => {

        if (alias.workspaceId && alias.portalId && globals.sessionId && globals.sessionUserId) {
            const intervalId = setInterval(async () => {

                try {
                    if (!document.hidden) {
                        const pingResponse = await PingSession(alias.workspaceId, alias.portalId, globals.sessionId, globals.sessionUserId, globals.jwtUserIdToken,false);
                        pingResponse && dispatch(setSessionId(pingResponse?.sessionId))
                        console.log(pingResponse);
                    }
                } catch (error) {
                    console.error(error);
                }
            }, 60000); // 60000 ms = 1 minute

            // Cleanup interval on component unmount
            return () => clearInterval(intervalId);
        }
    }, [alias.workspaceId, alias.portalId, globals.sessionId, globals.sessionUserId]);



    return null; // No UI rendering needed here
};

export default AppLogic;
